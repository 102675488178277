import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { StyledMaterialDesignContent } from "./hooks/useNotification";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Root from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PersistGate } from "redux-persist/integration/react";
import { lazy } from "react";

const GlobalLayout = lazy(() => import("./components/Layout"));
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <BrowserRouter>
            <GlobalLayout>
              <SnackbarProvider
                maxSnack={3}
                hideIconVariant
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Root />
                </LocalizationProvider>
              </SnackbarProvider>
            </GlobalLayout>
          </BrowserRouter>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
