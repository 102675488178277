import { Box, TextField as MuiTextField, styled } from "@mui/material";
interface props {
  name: string;
  label: string;
  type: "name" | "email" | "password";
  value: string;
  onChange: any;
  onBlur: any;
  helperText: string | undefined | false;
  error: boolean | undefined;
  bgcolor?: string;
  disabled?: boolean;
  profile?: boolean;
  multiline?: boolean;
}

type TextFieldProps = {
  borderColor?: string;
};

const outlinedSelectors = [
  "& .MuiOutlinedInput-notchedOutline",
  "&:hover .MuiOutlinedInput-notchedOutline",
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline",
];

export const TextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== "borderColor",
})<TextFieldProps>(({ borderColor }) => ({
  "& label.Mui-focused": {
    color: borderColor,
  },
  [outlinedSelectors.join(",")]: {
    borderWidth: 1,
    borderColor,
  },
}));
const FormInputs = (props: props) => (
  <Box>
    <TextField
      fullWidth
      borderColor="#781914"
      disabled={props.disabled}
      value={props.value}
      multiline={props.multiline}
      minRows={3}
      InputProps={{
        sx: { borderRadius: "4px", fontFamily: "livvic" },
      }}
      inputProps={{
        style: {
          WebkitBoxShadow: !props?.profile
            ? `0 0 0 1000px #fff0d7 inset`
            : undefined,
          WebkitTextFillColor: !props?.disabled ? "#781914" : undefined,
        },
      }}
      InputLabelProps={{ sx: { color: "#781914", fontFamily: "livvic" } }}
      onChange={props.onChange}
      name={props.name}
      type={props.type}
      size="small"
      title={props.name}
      label={props.label}
      FormHelperTextProps={{ sx: { fontFamily: "livvic", color: "#781914" } }}
      helperText={props.helperText}
      variant="outlined"
    />
  </Box>
);
export default FormInputs;
