import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { createRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

interface props {
  label: string;
  value: string;
  ontextChange: any;
  onstoryChange: any;
  onBlur: any;
  helperText: string | undefined | false;
  error: boolean | undefined;
}
const TextEditor = (props: props) => {
  const editor = createRef<ReactQuill>();
  return (
    <FormControl fullWidth error={props.error}>
      <FormLabel error={false}>{props.label}</FormLabel>
      <ReactQuill
        style={{ backgroundColor: "#fff5e6" }}
        theme="snow"
        value={props.value}
        onChange={(x) => {
          props.onstoryChange(x);
          props.ontextChange(editor.current?.getEditor().getText());
        }}
        ref={editor}
      />
      <FormHelperText sx={{ m: 0 }}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default TextEditor;
