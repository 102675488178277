import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../hooks/useNotification";
import { t } from "i18next";
import { activateLinkService } from "../../services/authservice";

const ActivationLink = () => {
  const { token } = useParams();
  const Notify = useNotification();
  const [disabled, setdisabled] = useState(false);
  const navigate = useNavigate();
  const handleRequest = async () => {
    try {
      if (disabled) return;
      setdisabled(true);
      const res = await activateLinkService(token as string);
      if (res.status === 200) {
        Notify(t("Account activated successfully"), "success");
        navigate("/");
        setdisabled(false)
      }
    } catch (error) {
      setdisabled(false)
      navigate("/");
      Notify(t("Invalid token"), "error");
    }
  };
  useEffect(() => {
    handleRequest();
  }, []);
  return <Typography></Typography>;
};

export default ActivationLink;
