"use client";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./ShowTable.style.css";
import { t } from "i18next";
import { Switch, TableCell, TableFooter, TablePagination } from "@mui/material";
import { useState } from "react";
import { useNotification } from "../../hooks/useNotification";
import { publishTags, publisheTopic } from "../../services/filterservice";
import DeleteTagsandTopic from "../DeleteTagsAndTopics";

const DekstopRow = (props: {
  row: any;
  handleClick: (id: string, bool: boolean) => void;
  setdeleted: any;
}) => {
  const { row } = props;
  return (
    <>
      <TableRow>
        <TableCell
          component="th"
          sx={{ maxWidth: "400px" }}
          scope="row"
          className="text-overflow-ellipsis"
        >
          <Typography sx={{ fontFamily: "livvic" }}>{row?.name}</Typography>
        </TableCell>
        <TableCell
          className="text-overflow-ellipsis"
          sx={{
            minWidth: "100px",
            display: "flex",
            alignItems: "center",
          }}
          align="right"
        >
          <IconButton onClick={() => props.setdeleted(row)}>
            <DeleteOutlineIcon />
          </IconButton>
          <Switch
            checked={row?.published ? true : false}
            onClick={(e: any) => props.handleClick(row?._id, e.target.checked)}
          />
          <Typography sx={{ fontFamily: "livvic" }}>
            {row?.published ? `${t("Unpublish")} ?` : `${t("Publish")} ?`}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const EnchancedDekstopHeader = (type: "tags" | "topics") => (
  <TableHead
    sx={{ "& .MuiTableCell-stickyHeader": { backgroundColor: "#EEE" } }}
  >
    <TableRow>
      <TableCell>
        {type === "tags" ? t("Table.tagName") : t("Table.topicName")}
      </TableCell>
      <TableCell />
    </TableRow>
  </TableHead>
);

const ShowTable = ({
  array,
  type,
  settoggle,
}: {
  array: any[];
  type: "tags" | "topics";
  settoggle: any;
}) => {
  const [page, setpage] = useState(0);
  const [deleteitem, setdelete] = useState<any>(null);
  const notify = useNotification();
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const handleClick = async (id: string, bool: boolean) => {
    try {
      const res = await (type === "tags"
        ? publishTags(id, bool)
        : publisheTopic(id, bool));
      if (res.status === 200) {
        type === "tags"
          ? notify(
              t(`Tag ${bool ? "published" : "unpublished"} successfully`),
              "success"
            )
          : notify(
              t(`Topic ${bool ? "published" : "unpublished"} successfully`),
              "success"
            );
        settoggle((prev: boolean) => !prev);
      }
    } catch (error) {
      notify(t("Error.serverError"), "error");
    }
  };
  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <TableContainer
        component={Paper}
        elevation={0}
        variant="outlined"
      >
        <Table stickyHeader size="small">
          {EnchancedDekstopHeader(type)}
          {array?.length !== 0 ? (
            <TableBody>
              {array?.map((row, idx) => {
                if (
                  idx >= page * rowsPerPage &&
                  idx < (page + 1) * rowsPerPage
                ) {
                  return (
                    <DekstopRow
                      setdeleted={setdelete}
                      handleClick={handleClick}
                      key={idx}
                      row={row}
                    />
                  );
                }
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={5}
                  sx={{
                    borderBottom: {
                      xs: "none",
                      sm: "1px solid rgba(224, 224, 224, 1);",
                    },
                  }}
                ></TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableFooter>
            {array?.length && (
              <TableRow>
                <TableCell colSpan={5}>
                  <TablePagination
                    variant="footer"
                    sx={{
                      width: "100%",
                      fontFamily: "livvic",
                    }}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} ${t("of")} ${count}`
                    }
                    labelRowsPerPage={t("Rows per page: ")}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={array?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, p) => setpage(p)}
                    onRowsPerPageChange={(e) => {
                      setpage(0);
                      setrowsPerPage(parseInt(e.target.value));
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {deleteitem && (
        <DeleteTagsandTopic item={deleteitem} setItem={setdelete} type={type} />
      )}
    </Box>
  );
};
export default ShowTable;
