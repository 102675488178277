import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { styled } from "@mui/system";

const Textarea = styled(BaseTextareaAutosize)(
  () => `
  box-sizing: border-box;
  font-family: 'livvic', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  color: #424242;
  background: #fff5e6;
  border: none;
  box-shadow: none;
  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
  &:focus-visible {
    outline: 0;
  }
`
);

interface props {
  label: string;
  onChange: any;
  onBlur: any;
  error: boolean | undefined;
  helperText: string | undefined | false;
  value?: any;
}
const FormTextArea = (props: props) => {
  return (
    <FormControl fullWidth error={props.error}>
      <FormLabel error={false}>{props.label}</FormLabel>
      <Textarea
        value={props.value}
        onBlur={props.onBlur}
        onChange={(e) => props.onChange(e.target.value)}
        sx={{ width: "100%" }}
        aria-label="minimum height"
        minRows={4}
      />
      <FormHelperText sx={{ m: 0 }}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
export default FormTextArea;
