"use client";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./ShowTable.style.css";
import { t } from "i18next";
import { TableFooter, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Eye from "@mui/icons-material/VisibilityOutlined";
import More from "@mui/icons-material/MoreVertOutlined";
import { genderDecrypter } from "../../utils/gender";
import { countryDecryoter } from "../../utils/country";
import { AxiosError } from "axios";
import React from "react";
import { useNotification } from "../../hooks/useNotification";
import {
  sendActivationLinkService,
  disableUserService,
} from "../../services/adminservice";
import UserAction from "../UserList/UserAction";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUsersAction } from "../../features/adminSlice/admin.action";
import { adminSelector } from "../../features/adminSlice/admin.slice";
import SearchBar from "../SearchBar";

const DekstopRow = (props: any) => {
  const { row, setselectedUser, sendActivationLink, disableUser } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          onClick={() => setselectedUser(row)}
          sx={{
            position: "sticky",
            left: 0,
            cursor: "pointer",
            background: "white",
            borderRight: "1px solid lightgrey",
          }}
          className="text-overflow-ellipsis"
        >
          <Typography sx={{ fontFamily: "livvic" }}>
            {`${row?.firstName} ${row?.lastName}`}
          </Typography>
        </TableCell>
        <TableCell
          // sx={{ maxWidth: "200px" }}
          component="th"
          className="text-overflow-ellipsis"
        >
          <Typography sx={{ fontFamily: "livvic" }}>{row.email}</Typography>
        </TableCell>
        <TableCell
          // sx={{ maxWidth: "200px" }}
          component="th"
          className="text-overflow-ellipsis"
        >
          <Typography sx={{ fontFamily: "livvic" }}>
            {genderDecrypter(row.gender)}
          </Typography>
        </TableCell>
        <TableCell
          // sx={{ maxWidth: "150px" }}
          component="th"
          className="text-overflow-ellipsis"
        >
          <Typography sx={{ fontFamily: "livvic" }}>
            {countryDecryoter(row.country)}
          </Typography>
        </TableCell>
        <TableCell className="text-overflow-ellipsis" align="right">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <More />
          </IconButton>
        </TableCell>
      </TableRow>
      <UserAction
        user={row}
        sendActivationLink={sendActivationLink}
        disableUser={disableUser}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

const EnchancedDekstopHeader = () => (
  <TableHead
    sx={{ "& .MuiTableCell-stickyHeader": { backgroundColor: "#EEE" } }}
  >
    <TableRow>
      <TableCell
        sx={{
          position: "sticky",
          left: 0,
          zIndex: 100,
          background: "white",
          borderRight: "1px solid lightgrey",
        }}
      >
        {t("Table.Name")}
      </TableCell>
      <TableCell>{t("Table.Email")}</TableCell>
      <TableCell>{t("Table.Gender")}</TableCell>
      <TableCell>{t("Table.Country")}</TableCell>
      <TableCell />
    </TableRow>
  </TableHead>
);

const UserTable = ({
  setselectedUser,
}: {
  setselectedUser: React.Dispatch<React.SetStateAction<User | null>>;
}) => {
  const [page, setpage] = useState(0);
  const { users, totalUsers, loading } = useAppSelector(adminSelector);
  const dispatch = useAppDispatch();
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [searchStr, setsearchStr] = useState("");
  const Notify = useNotification();
  const sendActivationLink = async (userId: string | null | undefined) => {
    if (userId) {
      try {
        const res = await sendActivationLinkService(userId);
        if (res.status === 200) {
          return Notify("Activation link sent successfully", "success");
        }
      } catch (error: any) {
        const err = error as AxiosError;
        if (err?.response?.status === 409) {
          return Notify("Account is already activated", "error");
        }
        console.log(error);
      }
    }
  };
  // const ResendPasswordResetLink = async (userId: string | null | undefined) => {
  //   if (userId) {
  //     try {
  //       const res = await ResendPasswordLinkService(userId);
  //       if (res.status === 200) {
  //         return Notify("Activation link sent successfully", "success");
  //       }
  //     } catch (error: any) {
  //       const err = error as AxiosError;
  //       if (err?.response?.status === 409) {
  //         return Notify("Account is already activated", "error");
  //       }
  //       console.log(error);
  //     }
  //   }
  // };
  const disableUser = async (
    userId: string | null | undefined,
    status: number
  ) => {
    if (userId) {
      try {
        const res = await disableUserService(userId, { status: status });
        if (res.status === 200) {
          return Notify("Successfully disabled user", "success");
        }
      } catch (error: any) {
        const err = error as AxiosError;
        if (err?.response?.status === 409) {
          return Notify("Account is already activated", "error");
        }
        console.log(error);
      }
    }
  };
  useEffect(() => {
    dispatch(
      getUsersAction({
        page: page + 1,
        limit: rowsPerPage,
        name: searchStr.trim() !== "" ? searchStr : undefined,
      })
    );
  }, [page, rowsPerPage, searchStr]);
  return (
    <Box sx={{ overflow: "auto", p: 1, bgcolor: "#fff0d7" }}>
      <SearchBar index={0} searchStr={searchStr} setsearchStr={setsearchStr} />
      {users.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            overflow: "auto",
            mt: 1,
            minHeight: "100%",
          }}
          variant="outlined"
        >
          <Table stickyHeader size="small">
            {EnchancedDekstopHeader()}
            {users?.length !== 0 ? (
              <TableBody>
                {users?.map((row, idx) => (
                  <DekstopRow
                    disableUser={disableUser}
                    sendActivationLink={sendActivationLink}
                    setselectedUser={setselectedUser}
                    key={idx}
                    row={row}
                  />
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={5}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: "1px solid rgba(224, 224, 224, 1);",
                      },
                    }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableFooter>
              {users?.length && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <TablePagination
                      variant="footer"
                      sx={{
                        width: "100%",
                      }}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} ${t("of")} ${count}`
                      }
                      labelRowsPerPage={t("Rows per page: ")}
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={totalUsers}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, p) => setpage(p)}
                      onRowsPerPageChange={(e) => {
                        setpage(0);
                        setrowsPerPage(parseInt(e.target.value));
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            width: { xs: "100%", md: "770px" },
            display: "flex",
            p: 10,
            bgcolor: "#fcf7ee",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontFamily: "livvic", fontWeight: 500 }}
          >
            {t("admin.noUsers")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default UserTable;
