import { Paper, Grid, Button } from "@mui/material";
import { t } from "i18next";
import FormSelectBox from "../FormSelectBox";
import FormInputs from "../FormInputs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { useNotification } from "../../hooks/useNotification";
import { StorySchema } from "../../utils/validation";
import TextEditor from "../TextEditor";
import FormTextArea from "../FormTextarea";
import { updateStoryAction } from "../../features/storySlice/story.action";
import languages from "../../static/languages.json";
import countries from "../../static/countires.json";
import TopicSelect from "../TopicSelector";
import "./style.css";
import Cncel from "@mui/icons-material/Close";
import { setCurrentStory } from "../../features/storySlice/story.slice";

interface props {
  keywords: any[];
  topics: any[];
  story: storyType;
}
const UpdateStoryEditor = (props: props) => {
  const dispatch = useAppDispatch();
  const { story } = props;
  const Notify = useNotification();
  const [disabled, setdisabled] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: story.title,
      story: story.story,
      rawText: `${story.story.replace(/<[^>]*>/g, "") as string}`,
      topic: story.topic,
      keywords: story.keywords,
      language: languages.find((x) => x.code === story.language)?.native_name,
      country: countries.find((x) => x.alpha2 === story.country)?.en,
      lesson: story.lesson,
    },
    validationSchema: StorySchema,
    onSubmit: async (values) => {
      if (disabled) return;
      setdisabled(true);
      const obj = {
        ...values,
        _id: story._id,
        rawText: undefined,
        keywords: (values.keywords as any).map((val: any) =>
          typeof val === "string" ? val : val?.name
        ),
        topic:
          typeof values.topic === "string"
            ? values.topic
            : (values.topic as any)?.name,
        language: languages.find(
          (x) => x.native_name === values.language || x.code === values.language
        )?.code as string,
        country: countries.find(
          (x) => x.alpha2 === story.country || x.en === story.country
        )?.alpha2 as string,
      };
      await handleSubmit(obj);
    },
  });
  const handleSubmit = async (values: storyType) => {
    const res = await dispatch(updateStoryAction(values));
    if (res.meta.requestStatus === "rejected" && res.meta.rejectedWithValue) {
      setdisabled(false);
      return Notify(t("Error.serverError"), "error");
    }
    setdisabled(false);
    Notify(t("Story updated successfully"), "success");
    dispatch(setCurrentStory(null));
  };
  useEffect(() => {
    formik.setFieldValue(
      "rawText",
      story.story.replace(/<[^>]*>/g, "") as string,
      true
    );
  }, []);
  return (
    <Paper sx={{ bgcolor: "#fee5b6", p: "24px 12px" }}>
      <form className="signup-form" onSubmit={formik.handleSubmit}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormInputs
            name="title"
            label={t("newStory.titleLabel")}
            bgcolor={"#fff5e6"}
            type="name"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.title && formik.errors.title}
            error={formik.touched.title && Boolean(formik.errors.title)}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TextEditor
            label={t("newStory.storyLabel")}
            value={formik.values.story}
            ontextChange={(val: string) =>
              formik.setFieldValue("rawText", val, true)
            }
            onstoryChange={(val: string) =>
              formik.setFieldValue("story", val, true)
            }
            onBlur={formik.handleBlur}
            helperText={formik.touched.rawText && formik.errors.rawText}
            error={formik.touched.rawText && Boolean(formik.errors.rawText)}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormSelectBox
            searchType={true}
            label={t("Country")}
            value={formik.values.country}
            options={countries}
            valueLabel="en"
            placeholder=""
            onChange={(val: string) => {
              formik.setFieldValue("country", val, true);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            multiple={false}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TopicSelect
            multiple={true}
            label={t("Tags")}
            value={formik.values.keywords}
            valueLabel="name"
            options={props.keywords}
            onChange={(val: string) => {
              formik.setFieldValue("keywords", val, true);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.keywords && Boolean(formik.errors.keywords)}
            helperText={formik.touched.keywords && formik.errors.keywords}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <TopicSelect
            options={props.topics}
            value={formik.values.topic}
            valueLabel="name"
            multiple={false}
            label={t("Topics")}
            onChange={(val: string) => {
              formik.setFieldValue("topic", val, true);
            }}
            error={formik.touched.topic && Boolean(formik.errors.topic)}
            onBlur={formik.handleBlur}
            helperText={formik.touched.topic && formik.errors.topic}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormTextArea
            label={t("Lesson")}
            value={formik.values.lesson}
            onChange={(val: string) => {
              formik.setFieldValue("lesson", val, true);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.lesson && Boolean(formik.errors.lesson)}
            helperText={formik.touched.lesson && formik.errors.lesson}
          />
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormSelectBox
            label={t("Language")}
            searchType={true}
            value={formik.values.language}
            options={languages}
            valueLabel="native_name"
            placeholder=""
            onChange={(val: string) => {
              formik.setFieldValue("language", val, true);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.language && Boolean(formik.errors.language)}
            helperText={formik.touched.language && formik.errors.language}
            multiple={false}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            className="update-button"
            disableElevation
            sx={{
              width: "100px",
              fontSize: "14px",
              fontFamily: "livvic",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#F9BB30E6",
              },
            }}
          >
            {t("Update")}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            disableElevation
            type="button"
            className="update-cancel-button"
            startIcon={<Cncel />}
            onClick={(e) => {
              dispatch(setCurrentStory(null));
            }}
          >
            {t("Cancel")}
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default UpdateStoryEditor;
