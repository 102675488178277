import { axiosApi } from "../config/axiosApi";

export const createStoryService = (body: storyType) =>
  axiosApi.post("/stories", body);

export const getStoriesService = (params: string) =>
  axiosApi.get(`/stories${params}`);

export const getMyStoriesService = (params: string) =>
  axiosApi.get(`/stories/my-stories${params}`);

export const getCountryDataService = () =>
  axiosApi.get("/stories/country-data");

export const changeStoryTranslationService = (id: string, language: string) =>
  axiosApi.get(`/stories/${id}?language=${language}`);

export const publishStoryService = (id: string, bool: boolean) =>
  axiosApi.patch(`/stories/${id}/publish`, { publish: bool });

export const updateStoryService = (id: string, val:storyType) =>
  axiosApi.put(`/stories/${id}`, val);

export const deleteStoryService = (id: string) =>
  axiosApi.delete(`/stories/${id}`);
