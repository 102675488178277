import { RootState } from "../../store";
import { createSlice } from "@reduxjs/toolkit";
import {
  changeStoryTranslation,
  createStoryAction,
  getStoriesAction,
  getCountryDataAction,
  getMyStoriesAction,
  updateStoryAction,
  deleteStoryAction,
} from "./story.action";
import countries from "../../static/countires.json";
import _ from "lodash";
type InitialStateType = {
  stories: storyType[] | [];
  myStory: storyType[] | [];
  currentStory: storyType | null;
  countryData: any[] | [];
  total: number;
  loading: boolean;
  error: string | null;
};
const initialState: InitialStateType = {
  loading: false,
  error: null,
  stories: [],
  currentStory: null,
  total: 0,
  myStory: [],
  countryData: [],
};
export const storySlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    clearStories: (state) => {
      state.stories = [];
      return state;
    },
    changeStoryStatus: (state, { payload }) => {
      state.stories = state.stories.map((e) => {
        if (e._id === payload._id) {
          e.published = payload.bool;
        }
        return e;
      });
    },
    setCurrentStory: (state, { payload }) => {
      state.currentStory = payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createStoryAction.fulfilled, (state, action) => {
        state.myStory = [...state.myStory, action?.payload];
        state.loading = false;
        return state;
      })
      .addCase(createStoryAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(createStoryAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(getStoriesAction.fulfilled, (state, action) => {
        state.stories = [...action?.payload.data];
        state.stories = _.unionBy(state.stories, "_id").map((story) => story);
        state.total = action.payload?.filteredTotal;
        state.loading = false;
        return state;
      })
      .addCase(getStoriesAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getStoriesAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(getCountryDataAction.fulfilled, (state, action) => {
        state.countryData = action.payload.map((country: any) => {
          let obj: any = countries.find(
            (cou) => cou.alpha2 === country?.country?.toLowerCase()
          );
          const newObj = { ...obj, count: country.count };
          return newObj;
        });
      })
      .addCase(getCountryDataAction.pending, (state) => {})
      .addCase(getCountryDataAction.rejected, (state) => {})
      .addCase(changeStoryTranslation.fulfilled, (state, action) => {
        const index = state.stories.findIndex(
          (story) => story._id === action.payload.data._id
        );
        const myStoryindex = state.myStory.findIndex(
          (story) => story._id === action.payload.data._id
        );
        state.stories[index] = action.payload.data;
        state.myStory[myStoryindex] = action.payload.data;
        return state;
      })
      .addCase(changeStoryTranslation.pending, (state) => {
        return state;
      })
      .addCase(changeStoryTranslation.rejected, (state) => {
        return state;
      })
      .addCase(getMyStoriesAction.fulfilled, (state, action) => {
        state.myStory = action?.payload?.data;
        state.loading = false;
        return state;
      })
      .addCase(getMyStoriesAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getMyStoriesAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(updateStoryAction.fulfilled, (state, action) => {
        state.loading = false;
        return state;
      })
      .addCase(updateStoryAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(updateStoryAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(deleteStoryAction.fulfilled, (state, action) => {
        state.stories = state.stories.filter((x) => x._id !== action.payload);
        state.myStory = state.myStory.filter((x) => x._id !== action.payload);
        state.total--;
        state.loading = false;
        return state;
      })
      .addCase(deleteStoryAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(deleteStoryAction.rejected, (state) => {
        state.loading = false;
        return state;
      });
  },
});
export const { clearStories, changeStoryStatus, setCurrentStory } =
  storySlice.actions;
export const storySelector = (state: RootState) => state.story;
export default storySlice.reducer;
