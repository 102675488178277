import * as React from "react";
import List from "@mui/material/List";
import { useAppDispatch } from "../../hooks";
import _ from "lodash";
import ProfileModal from "./ProfileModal";
import UserTable from "../Table/userTable";

export default function UserList() {
  const dispatch = useAppDispatch();
  const [selectedUser, setselectedUser] = React.useState<User | null>(null);
  const [selectedIndex, setselectedIndex] = React.useState(0);
  return (
    <List disablePadding sx={{ bgcolor: "background.paper" }}>
      <UserTable setselectedUser={setselectedUser} />
      {selectedIndex === 0 && (
        <ProfileModal
          selectedUser={selectedUser}
          setselectedUser={setselectedUser}
        />
      )}
    </List>
  );
}
