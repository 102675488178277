"use client";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import englishLanguage from "./en.json";
import hindi from "./hi.json";
import spanish from "./es.json";
import french from "./fr.json";
import arab from "./ar.json";
import thai from "./th.json";
import dutch from "./nl.json";
import bahasa from "./id.json";
import swahili from "./sw.json";

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: {
      "en-US": {
        translation: englishLanguage,
      },
      "hi-IN": {
        translation: hindi,
      },
      "id-ID": {
        translation: bahasa,
      },
      "es-ES": {
        translation: spanish,
      },
      "fr-FR": {
        translation: french,
      },
      "ar-AE": {
        translation: arab,
      },
      "th-TH": {
        translation: thai,
      },
      "nl-NL": {
        translation: dutch,
      },
      "sw-KE": {
        translation: swahili,
      },
    },
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
    },
  });
