import { Avatar, Box, Paper, Typography } from "@mui/material";
import "./style.css";
import country from "../../static/countires.json";
import languages from "../../static/languages.json";
import allLanguages from "../../static/allLanguages.json";
import { LocationOn, Mail, Language, CalendarMonth } from "@mui/icons-material";
import { genderDecrypter } from "../../utils/gender";
const ViewProfile = ({ user }: { user: User | null }) => {
  return (
    <Paper className="card-container" sx={{ bgcolor: "#fee5b6" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box className="round">
          <Avatar
            sx={{ width: 100, height: 100 }}
            src={user?.profilePic ?? ""}
          />
        </Box>
      </Box>
      <Typography sx={{ fontFamily: "livvic", fontSize: "22px" }}>
        {`${user?.firstName} ${user?.lastName}`}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          ml: "-12px",
          fontFamily: "livvic",
          fontSize: "14px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {user?.email && <Mail fontSize="small" />}
        {user?.email}
      </Typography>
      <Typography
        sx={{
          fontFamily: "livvic",
          display: "flex",
          ml: "-12px",
          fontSize: "14px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {user?.dob && <CalendarMonth fontSize="small" />}
        {user?.dob} {user?.gender && `(${genderDecrypter(user?.gender)})`}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          ml: "-12px",
          fontFamily: "livvic",
          fontSize: "14px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {user?.country && <LocationOn fontSize="small" />}
        {
          country.find(
            (country) => country.alpha2 === user?.country.toLowerCase()
          )?.en
        }
        {user?.primaryLanguage && <Language fontSize="small" />}
        {languages.find((x) => x.code === user?.primaryLanguage)?.native_name ??
          allLanguages.find(
            (x) => x.code === user?.primaryLanguage?.split("-")[0]
          )?.native}
      </Typography>
      <Typography sx={{ fontFamily: "livvic", mt: 1 }}>{user?.bio}</Typography>
    </Paper>
  );
};

export default ViewProfile;
