import { RootState } from "../../store";
import { createSlice } from "@reduxjs/toolkit";
import {
  deleteTagsAction,
  deleteTopicsAction,
  getAuthorAction,
  getKeywordsAction,
  getTopicsAction,
} from "./filter.action";
import _ from "lodash";

type InitialStateType = {
  keywords: any[];
  topics: any[];
  authors: any[];
  loading: boolean;
  error: string | null;
};
const initialState: InitialStateType = {
  loading: false,
  error: null,
  keywords: [],
  authors: [],
  topics: [],
};
export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getKeywordsAction.fulfilled, (state, action) => {
        state.keywords = _.unionBy(action.payload.data, "name").map(
          (story) => story
        );
        state.loading = false;
        return state;
      })
      .addCase(getKeywordsAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getKeywordsAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(getTopicsAction.fulfilled, (state, action) => {
        state.topics = _.unionBy(action.payload.data, "name").map(
          (story) => story
        );
        state.loading = false;
        return state;
      })
      .addCase(getTopicsAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getTopicsAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(getAuthorAction.fulfilled, (state, action) => {
        state.authors = [...action?.payload?.data];
        state.loading = false;
        return state;
      })
      .addCase(getAuthorAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getAuthorAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(deleteTagsAction.fulfilled, (state, action) => {
        state.keywords = state.keywords.filter((x) => x._id !== action.payload);
        state.loading = false;
        return state;
      })
      .addCase(deleteTagsAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(deleteTagsAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(deleteTopicsAction.fulfilled, (state, action) => {
        state.topics = state.topics.filter((x) => x._id !== action.payload);
        state.loading = false;
        return state;
      })
      .addCase(deleteTopicsAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(deleteTopicsAction.rejected, (state) => {
        state.loading = false;
        return state;
      });
  },
});
export const {} = filterSlice.actions;
export const filterSelector = (state: RootState) => state.filter;
export default filterSlice.reducer;
