import { axiosApi } from "../config/axiosApi";

export const getStoriesService = () => axiosApi.get("/stories");

export const getUsersService = (params: string) =>
  axiosApi.get(`/users${params}`);

export const sendActivationLinkService = (userId: string) =>
  axiosApi.get(`/users/${userId}/activation-link`);

export const disableUserService = (userId: string, data: { status: number }) =>
  axiosApi.patch(`/users/${userId}`, data);
