import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getKeywords,
  getTopics,
  getAuthors,
  deleteTag,
  deleteTopic,
} from "./filter.type";
import { AxiosError } from "axios";
import { t } from "i18next";
import {
  getAuthorService,
  getKeywordsService,
  getTopicsService,
  deleteTagService,
  deleteTopicService,
} from "../../services/filterservice";
import { urlSearchParams } from "../../utils/urlParamsCreator";

export const getKeywordsAction = createAsyncThunk(
  getKeywords,
  async (filters: any, { rejectWithValue }) => {
    try {
      const params = filters ? `?${urlSearchParams(filters)}` : "";
      const response = await getKeywordsService(params);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const getTopicsAction = createAsyncThunk(
  getTopics,
  async (filters: any, { rejectWithValue }) => {
    try {
      const params = filters ? `?${urlSearchParams(filters)}` : "";
      const response = await getTopicsService(params);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const getAuthorAction = createAsyncThunk(
  getAuthors,
  async (language: string, { rejectWithValue }) => {
    try {
      const response = await getAuthorService(language);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const deleteTagsAction = createAsyncThunk(
  deleteTag,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteTagService(id);
      if (response.status === 200) {
        return id;
      }
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const deleteTopicsAction = createAsyncThunk(
  deleteTopic,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteTopicService(id);
      if (response.status === 200) {
        return id;
      }
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);
