import { axiosApi } from "../config/axiosApi";

export const validateSessionService = () => axiosApi.get("/auth/checkSession");

export const loginUserService = (body: loginUserType) =>
  axiosApi.post("/auth/signin", body);

export const signupUserService = (body: signupUserType) =>
  axiosApi.post("/signup", body);

export const forgotPasswordService = (body: { email: string }) =>
  axiosApi.post("/auth/forgot-password", body);

export const resetpasswordService = (body: resetpassword, token: string) =>
  axiosApi.post(`/auth/reset-password/${token}`, body);

export const activateLinkService = (token: string) =>
  axiosApi.post(`/auth/activate-account/${token}`, { token: token });

export const updateUserService = (body: profileUserType, id: string) =>
  axiosApi.post(`/users/${id}/profile`, body);

export const logoutUserService = () =>
  axiosApi.get("/auth/logout");