import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import "./style.css";

const NotFound = () => {
  return (
    <Box className="notfound-container">
      <NotInterestedIcon fontSize="large" />
      <Typography className="notfound-title">{t("NotFound")}</Typography>
    </Box>
  );
};

export default NotFound;
