import { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  fillUserDetails,
  setLogoutState,
  userSelector,
} from "../features/userSlice/user.slice";
import { useNotification } from "../hooks/useNotification";
import { t } from "i18next";
import { validateSessionAction } from "../features/authSlice/auth.action";
import { authSelector } from "../features/authSlice/auth.slice";
import Loading from "../pages/Loading";

interface AuthRouteProps {
  requirement: boolean;
  redirectionURL: string;
  children: ReactNode;
}

export const AccessControl = (props: AuthRouteProps) => {
  const { userData } = useAppSelector(userSelector);
  const showNotification = useNotification();
  const { children, redirectionURL } = props;
  const { verified, loading } = useAppSelector(authSelector);
  const [comp, setcomp] = useState<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userData?.email && userData?._id && userData?.role === 2 && verified) {
      return setcomp(<div>{children}</div>);
    } else {
      dispatch(validateSessionAction())
        .then((x) => {
          if (x.meta.requestStatus === "fulfilled") {
            if (x.payload?.role === 2) {
              return setcomp(<div>{children}</div>);
            }
            dispatch(fillUserDetails(x.payload));
            showNotification(t("Error.forbidden"), "error");
            return setcomp(
              <div>
                <Navigate to={redirectionURL} />
              </div>
            );
          }
          dispatch(fillUserDetails(null));
          showNotification(t("Error.loginAgain"), "error");
          return setcomp(
            <div>
              <Navigate to={redirectionURL} />
            </div>
          );
        })
        .catch((err) => {
          console.log(err);
          showNotification(t("Error.serverError"), "error");
          return setcomp(
            <div>
              <Navigate to={redirectionURL} />;
            </div>
          );
        });
    }
  }, [children]);
  return loading && !comp ? <Loading /> : comp;
};
