import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { LoginState } from "../middleware";
import NotFound from "../pages/NotFound";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";
import { AccessControl } from "../middleware/roleBased";
import Admin from "../pages/Admin";
import ActivationLink from "../pages/ActivationLink";

const Home = lazy(() => import("../pages/Home"));
const LoginPage = lazy(() => import("../pages/Login"));
const RegisterPage = lazy(() => import("../pages/SignUp"));
const MyStorypage = lazy(() => import("../pages/MyStory"));
const NewStory = lazy(() => import("../pages/NewStory"));
const ProfilePage = lazy(() => import("../pages/Profile"));
const Stories = lazy(() => import("../pages/Stories"));

const Root = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegisterPage />} />
        <Route path="/stories" element={<Stories />} />
        <Route
          path="/my-story"
          element={
            <LoginState requirement={false} redirectionURL={"/login"}>
              <MyStorypage />
            </LoginState>
          }
        />
        <Route
          path="/new-story"
          element={
            <LoginState requirement={false} redirectionURL={"/login"}>
              <NewStory />
            </LoginState>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/auth/activate-account/:token" element={<ActivationLink />} />
        <Route
          path="/profile"
          element={
            <LoginState requirement={false} redirectionURL={"/login"}>
              <ProfilePage />
            </LoginState>
          }
        />
        <Route
          path="/admin-control"
          element={
            <AccessControl requirement={false} redirectionURL={"/"}>
              <Admin />
            </AccessControl>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Root;
