import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createStory,
  getStoryById,
  getStories,
  getMyStories,
  getCountryData,
  updateStories,
  deleteStories
} from "./story.type";
import { AxiosError } from "axios";
import { t } from "i18next";
import {
  createStoryService,
  getStoriesService,
  changeStoryTranslationService,
  getCountryDataService,
  getMyStoriesService,
  updateStoryService,
  deleteStoryService
} from "../../services/storyservice";
import { urlSearchParams } from "../../utils/urlParamsCreator";

export const createStoryAction = createAsyncThunk(
  createStory,
  async (story_details: storyType, { rejectWithValue }) => {
    try {
      const response = await createStoryService(story_details);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const updateStoryAction = createAsyncThunk(
  updateStories,
  async (story_details: storyType, { rejectWithValue }) => {
    try {
      const { _id, ...others } = story_details;
      const response = await updateStoryService(_id as string, others);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const getStoriesAction = createAsyncThunk(
  getStories,
  async (filters: any | undefined, { rejectWithValue }) => {
    try {
      const params = filters ? `?${urlSearchParams(filters)}` : "";
      const response = await getStoriesService(params);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const changeStoryTranslation = createAsyncThunk(
  getStoryById,
  async (
    { id, language }: { id: string; language: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await changeStoryTranslationService(id, language);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const getCountryDataAction = createAsyncThunk(
  getCountryData,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCountryDataService();
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const getMyStoriesAction = createAsyncThunk(
  getMyStories,
  async (filters: any | undefined, { rejectWithValue }) => {
    try {
      const params = filters ? `?${urlSearchParams(filters)}` : "";
      const response = await getMyStoriesService(params);
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const deleteStoryAction = createAsyncThunk(
  deleteStories,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteStoryService(id);
      if (response.status === 200) {
        return id;
      }
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);