import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { userSelector } from "../features/userSlice/user.slice";
import { useNotification } from "../hooks/useNotification";
import { t } from "i18next";
import { authSelector } from "../features/authSlice/auth.slice";
import { validateSessionAction } from "../features/authSlice/auth.action";
import Loading from "../pages/Loading";

interface AuthRouteProps {
  requirement: boolean;
  redirectionURL: string;
  children: ReactNode;
}

export const LoginState = (props: AuthRouteProps): any => {
  const { children, redirectionURL } = props;
  const { userData } = useAppSelector(userSelector);
  const { verified, loading } = useAppSelector(authSelector);
  const [comp, setcomp] = useState<null | React.ReactNode>(null);
  const dispatch = useAppDispatch();
  const showNotification = useNotification();

  useEffect(() => {
    if (userData?.email && userData?._id && verified) {
      return setcomp(<div>{children}</div>);
    } else {
      dispatch(validateSessionAction())
        .then((x) => {
          if (x.meta.requestStatus === "fulfilled") {
            return setcomp(<div>{children}</div>);
          }
          showNotification(t("Error.loginAgain"), "error");
          return setcomp(
            <div>
              <Navigate to={redirectionURL} />
            </div>
          );
        })
        .catch((err) => {
          console.log(err);
          showNotification(t("Error.serverError"), "error");
          return setcomp(
            <div>
              <Navigate to={redirectionURL} />;
            </div>
          );
        });
    }
  }, [children]);
  return loading && !comp ? <Loading /> : comp;
};
