import InfoIcon from "@mui/icons-material/PeopleOutline";
import SupportAgentIcon from "@mui/icons-material/AutoStoriesOutlined";
import TagIcon from "@mui/icons-material/TagOutlined";
import TopicIcon from "@mui/icons-material/TopicOutlined";
import "./style.css";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

const pageMenuList = [
  {
    title: "admin.userlist",
    icon: (props: any) => <InfoIcon {...props} />,
    autofocus: true,
  },
  {
    title: "admin.storylist",
    icon: (props: any) => <SupportAgentIcon {...props} />,
  },
  {
    title: "admin.tags",
    icon: (props: any) => <TagIcon {...props} />,
  },
  {
    title: "admin.topics",
    icon: (props: any) => <TopicIcon {...props} />,
  },
];

interface props {
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
}

const MenuList = ({ active, setActive }: props) => {
  const setActiveindex = (idx: number) => {
    setActive(idx);
  };

  return (
    <List
      disablePadding
      sx={{
        display: { xs: "flex", md: "block" },
        position: "fixed",
        width: "180px",
      }}
    >
      {pageMenuList.map((menu, index) => (
        <ListItem
          key={index}
          disablePadding
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setActiveindex(index);
            }
          }}
          className={`consult-list-item ${active === index ? "active" : ""}`}
          onClick={() => setActiveindex(index)}
        >
          <ListItemButton sx={{ display: "flex", gap: "16px" }}>
            {menu.icon({ style: { color: "#781914" } })}
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontFamily: "livvic",
                fontWeight: 600,
                color: "#781914",
              }}
              primary={t(menu.title)}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const PageMenu = (props: props) => {
  return (
    <Box
      sx={{ width: "200px", display: { xs: "none", md: "block" } }}
      role="presentation"
    >
      <MenuList {...props} />
    </Box>
  );
};
export default PageMenu;
