import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { t } from "i18next";
import { useNotification } from "../../hooks/useNotification";
import { useAppDispatch } from "../../hooks";
import { deleteStoryAction } from "../../features/storySlice/story.action";
import { deleteTagsAction, deleteTopicsAction } from "../../features/filterSlice/filter.action";

interface props {
  item: any;
  setItem: any;
  type: "tags" | "topics";
}
export default function DeleteTagsandTopic(props: props) {
  const Notify = useNotification();
  const dispatch = useAppDispatch();
  const handlePublish = async () => {
    let res: any;
    if (props.type === "tags") {
      res = await dispatch(deleteTagsAction(props.item?._id));
    } else {
      res = await dispatch(deleteTopicsAction(props.item?._id));
    }
    if (res.meta.requestStatus === "rejected" && res.meta.rejectedWithValue) {
      props.setItem(null);
      return Notify(t("Error.serverError"), "error");
    }
    Notify(
      t(`${props.type === "tags" ? "Tag" : "Topic"} deleted successfully`),
      "success"
    );
    props.setItem(null);
  };
  return (
    <React.Fragment>
      <Dialog
        open={Boolean(props.item)}
        onClose={() => props.setItem(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontFamily: "livvic" }}>
          {t(`Delete ${props.type === "tags" ? "tag" : "topic"}`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontFamily: "livvic" }}
          >
            Do you really want to delete this{" "}
            {props.type === "tags" ? "tag" : "topic"}?`
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setItem(null)}
            sx={{ fontFamily: "livvic" }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handlePublish}
            autoFocus
            sx={{ fontFamily: "livvic" }}
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
