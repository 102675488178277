import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import ViewProfile from "../ViewProfile";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ProfileModal({
  selectedUser,
  setselectedUser,
}: {
  selectedUser: User | null;
  setselectedUser: any;
}) {
  return (
    <div>
      <Modal
        open={Boolean(selectedUser)}
        onClose={() => setselectedUser(null)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <ViewProfile user={selectedUser} />
        </Box>
      </Modal>
    </div>
  );
}
