import { RootState } from "../../store";
import { createSlice } from "@reduxjs/toolkit";
import { getUsersAction, getStoriesAction } from "./admin.action";
import _ from "lodash";
type InitialStateType = {
  stories: storyType[] | [];
  users: User[] | [];
  loading: boolean;
  totalStories: number;
  totalUsers: number;
  error: string | null;
};
const initialState: InitialStateType = {
  loading: false,
  error: null,
  totalStories: 0,
  totalUsers: 0,
  stories: [],
  users: [],
};
export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsersAction.fulfilled, (state, action) => {
        state.users = [...action?.payload.data];
        state.totalUsers = action.payload?.filteredTotal
        state.loading = false;
        return state;
      })
      .addCase(getUsersAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getUsersAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(getStoriesAction.fulfilled, (state, action) => {
        state.stories = [...state.stories, ...action?.payload.data];
        state.totalStories = action?.payload?.total;
        state.loading = false;
        return state;
      })
      .addCase(getStoriesAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(getStoriesAction.rejected, (state) => {
        state.loading = false;
        return state;
      });
  },
});
export const {} = adminSlice.actions;
export const adminSelector = (state: RootState) => state.admin;
export default adminSlice.reducer;
