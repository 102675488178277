import { t } from "i18next";

export const genderDecrypter = (gender: string) => {
  if (gender.trim() === "") {
    return "-";
  }
  if ("male".includes(gender.toLowerCase())) {
    return t("Male");
  }
  if ("female".includes(gender.toLowerCase())) {
    return t("Female");
  } else {
    return t("Others");
  }
};
