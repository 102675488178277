import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Story from ".";
import {
  getKeywordsAction,
  getTopicsAction,
  getAuthorAction,
} from "../../features/filterSlice/filter.action";
import { filterSelector } from "../../features/filterSlice/filter.slice";
import { getStoriesAction } from "../../features/storySlice/story.action";
import {
  storySelector,
  clearStories,
} from "../../features/storySlice/story.slice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { StorySearchSchema } from "../../utils/validation";
import FormSelectBox from "../FormSelectBox";
import TopicSelect from "../TopicSelector";
import countries from "../../static/countires.json";
import languages from "../../static/languages.json";

const StoryWrapper = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [disabled, setdisabled] = useState(false);
  const { keywords, topics, authors } = useAppSelector(filterSelector);
  const [status, setstatus] = useState<null | number>(null);
  const [filters, setfilters] = useState<any>();
  useEffect(() => {
    dispatch(getKeywordsAction(i18n.resolvedLanguage ?? "en-US"));
    dispatch(getTopicsAction(i18n.resolvedLanguage ?? "en-US"));
    dispatch(getAuthorAction(i18n.resolvedLanguage ?? "en-US"));
  }, []);
  const formik = useFormik({
    initialValues: {
      language: null,
      keywords: null,
      topics: null,
      creators: null,
      country: null,
      page: 1,
    },
    validationSchema: StorySearchSchema,
    onSubmit: async (values) => {
      if (disabled) return;
      setdisabled(true);
      const obj = {
        ...values,
        keywords: values.keywords ? (values.keywords as any[]).map((val) => val?._id) : undefined,
        topics: values.topics ? (values.topics as any)?._id : undefined,
        creators: values.creators ? (values.creators as any)?._id : undefined,
        published: status === 1 ? true : undefined,
        unpublished: status === 2 ? true : undefined,
        both: status === null ? true : undefined,
      };
      setfilters(obj);
      await handleSubmit(obj);
    },
  });
  const handleSubmit = async (values: any) => {
    dispatch(clearStories());
    const res = await dispatch(getStoriesAction(values));
    setdisabled(false);
  };
  return (
    <Box sx={{ p: { xs: 2, md: 0 }, bgcolor: "#fff0d7" }}>
      <Box>
        <Paper
          variant="outlined"
          sx={{ bgcolor: "#fee5b6", maxWidth: "770px", p: "24px 12px" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} rowGap={0}>
              <Grid item xs={12}>
                <Typography sx={{ pl: 2, fontSize: "18px" }} component="h6">
                  {t("Stories.searchLabel")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelectBox
                  label={t("Language")}
                  searchType={true}
                  options={languages}
                  valueLabel="native_name"
                  placeholder={t("All")}
                  onChange={(val: string) => {
                    formik.setFieldValue("language", val, true);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.language && Boolean(formik.errors.language)
                  }
                  helperText={formik.touched.language && formik.errors.language}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelectBox
                  label={t("Author")}
                  searchType={true}
                  options={authors}
                  placeholder={t("All")}
                  value={formik.values.creators}
                  valueLabel="authors"
                  onChange={(val: string) => {
                    formik.setFieldValue("creators", val, true);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.creators && Boolean(formik.errors.creators)
                  }
                  helperText={formik.touched.creators && formik.errors.creators}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelectBox
                  searchType={true}
                  label={t("Country")}
                  options={countries}
                  valueLabel="en"
                  placeholder={t("All")}
                  onChange={(val: string) => {
                    formik.setFieldValue("country", val, true);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TopicSelect
                  searchType={true}
                  label={t("Tags")}
                  options={keywords}
                  valueLabel="name"
                  placeholder={t("All")}
                  onChange={(val: string) => {
                    formik.setFieldValue("keywords", val, true);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.keywords && Boolean(formik.errors.keywords)
                  }
                  helperText={formik.touched.keywords && formik.errors.keywords}
                  multiple={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TopicSelect
                  searchType={true}
                  label={t("Topics")}
                  options={topics}
                  valueLabel="name"
                  placeholder={t("All")}
                  onChange={(val: string) => {
                    formik.setFieldValue("topics", val, true);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.topics && Boolean(formik.errors.topics)}
                  helperText={formik.touched.topics && formik.errors.topics}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="flex-end"
                  flex={1}
                  justifyContent="center"
                  sx={{
                    height: "100%",
                  }}
                >
                  <Chip
                    label={"Published"}
                    clickable
                    variant={status !== 1 ? "outlined" : "filled"}
                    sx={{
                      borderRadius: 0,
                      borderRight: 0,
                      fontFamily: "livvic",
                      bgcolor: status !== 1 ? "#fff5e6" : "#f9bb30",
                      "&:hover": {
                        backgroundColor: "#f9bb30",
                      },
                    }}
                    color={status !== 1 ? "default" : "primary"}
                    onClick={() => setstatus(1)}
                  />
                  <Chip
                    label={"Unpublished"}
                    clickable
                    variant={status !== 2 ? "outlined" : "filled"}
                    sx={{
                      borderRadius: 0,
                      borderRight: 0,
                      bgcolor: status !== 2 ? "#fff5e6" : "#f9bb30",
                      fontFamily: "livvic",
                      "&:hover": {
                        backgroundColor: "#f9bb30",
                      },
                    }}
                    color={status !== 2 ? "default" : "primary"}
                    onClick={() => setstatus(2)}
                  />
                  <Chip
                    label={"Both"}
                    clickable
                    variant={status !== null ? "outlined" : "filled"}
                    sx={{
                      borderRadius: 0,
                      bgcolor: status !== null ? "#fff5e6" : "#f9bb30",
                      fontFamily: "livvic",
                      "&:hover": {
                        backgroundColor: "#f9bb30",
                      },
                    }}
                    color={status !== null ? "default" : "primary"}
                    onClick={() => setstatus(null)}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disableElevation
                  sx={{
                    width: "100px",
                    fontSize: "14px",
                    bgcolor: "#F9BB30E6",
                    color: "#424242",
                    borderRadius: "20px",
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "#F9BB30E6",
                    },
                  }}
                >
                  {t("Search")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Story admin={true} />
      </Box>
    </Box>
  );
};

export default StoryWrapper;
