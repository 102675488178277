import { useEffect, useState } from "react";
import StoryCard from "../StoryCard";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { storySelector } from "../../features/storySlice/story.slice";
import {
  Box,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { t } from "i18next";
import { getStoriesAction } from "../../features/storySlice/story.action";
import { useTranslation } from "react-i18next";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#671a19" : "#308fe8",
  },
}));

const Story = ({ admin, Loading }: { admin: boolean; Loading?: boolean }) => {
  const dispatch = useAppDispatch();
  const { stories, loading, total } = useAppSelector(storySelector);
  const [page, setpage] = useState(1);
  useEffect(() => {
    dispatch(getStoriesAction({ page: page, both: admin ? true : undefined }));
  }, [page]);
  return (
    <>
      {loading ? (
        <BorderLinearProgress sx={{ width: { xs: "100%", md: "770px" } }} />
      ) : stories.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            bgcolor: "#fff0d7",
            gap: "16px",
          }}
        >
          <StoryCard admin={admin} stories={stories} />
          {window.location.pathname !== "/" && (
            <Stack width={"100%"} alignItems={"center"}>
              <Pagination
                siblingCount={0}
                defaultPage={page}
                boundaryCount={2}
                onChange={(e, val) => setpage(val)}
                count={Math.ceil(total / 10)}
                size="large"
              />
            </Stack>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#fcf7ee",
            alignItems: "center",
            p: "12px 0",
            gap: "16px",
            width: { xs: "100%", md: "770px" },
            maxWidth: "770px",
          }}
        >
          <Typography
            fontFamily={"livvic !important"}
            fontSize={"16px"}
            sx={{ color: "#424242" }}
            fontWeight={500}
          >
            {t("Home.NoStory")}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Story;
