import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { t } from "i18next";
import { useNotification } from "../../hooks/useNotification";
import { publishStoryService } from "../../services/storyservice";
import { useAppDispatch } from "../../hooks";
import { changeStoryStatus } from "../../features/storySlice/story.slice";

interface props {
  story: storyType | null;
  setStory: any;
}
export default function ApprovalModal(props: props) {
  const Notify = useNotification();
  const dispatch = useAppDispatch();
  const handlePublish = async () => {
    if (props.story) {
      try {
        const res = await publishStoryService(
          props.story?._id as string,
          props.story?.published ? false : true
        );
        if (res.status === 200) {
          props.setStory(null);
          Notify(
            t(
              `Story ${
                props.story.published ? "unpublished" : "published"
              } successfuly`
            ),
            "success"
          );
          return dispatch(
            changeStoryStatus({
              _id: props.story?._id,
              bool: !props.story?.published,
            })
          );
        }
      } catch (error) {
        props.setStory(null);
        return Notify(t("Error.serverError"), "error");
      }
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={Boolean(props.story)}
        onClose={() => props.setStory(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontFamily: "livvic" }}>
          {t(`${props.story?.published ? "Unpublish" : "Publish"} this story?`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontFamily: "livvic" }}
          >
            Do you really want to{" "}
            {props.story?.published ? "unpublish" : "publish"} this story
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setStory(null)}
            sx={{ fontFamily: "livvic" }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handlePublish}
            autoFocus
            sx={{ fontFamily: "livvic" }}
          >
            {props.story?.published ? t("Unpublish") : t("Publish")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
