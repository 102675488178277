import { Box, Paper, Stack } from "@mui/material";
import PageMenu from "../../components/AdminMenu";
import { useState, useEffect } from "react";
import UserList from "../../components/UserList";
import ShowTable from "../../components/Table";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { filterSelector } from "../../features/filterSlice/filter.slice";
import {
  getKeywordsAction,
  getTopicsAction,
} from "../../features/filterSlice/filter.action";
import { useTranslation } from "react-i18next";
import "./style.css";
import Story from "../../components/Story";
import {
  setCurrentStory,
  storySelector,
} from "../../features/storySlice/story.slice";
import UpdateStory from "../../components/UpdateStoryEditor";
import LabelBottomNavigation from "../../components/AdminMobileNavigation";
import TableWrapper from "../../components/Table/Wrapper";
import StoryWrapper from "../../components/Story/Wrapper";

const Admin = () => {
  const [activeIdx, setactiveIdx] = useState(0);
  const { currentStory } = useAppSelector(storySelector);
  const dispatch = useAppDispatch();
  const renderPage = () => {
    switch (activeIdx) {
      case 0:
        return <UserList />;
      case 1:
        return <StoryWrapper />;
      case 2:
        return <TableWrapper index={activeIdx} type="tags" />;
      case 3:
        return <TableWrapper index={activeIdx} type="topics" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(setCurrentStory(null));
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "1000px",
        pb: 7,
      }}
    >
      {currentStory ? (
        <UpdateStory story={currentStory} />
      ) : (
        <Stack
          id="consult-complaint-page"
          spacing={2}
          sx={{
            marginTop: {
              xs: "10px !important",
              md: "10px !important",
            },
          }}
          useFlexGap
          alignItems="flex-start"
          direction="row"
        >
          <PageMenu active={activeIdx} setActive={setactiveIdx} />
          <Stack
            sx={{
              height: "100%",
              p: activeIdx === 1 ? 0 : 2,
              overflow: "hidden",
            }}
            flex={1}
          >
            {renderPage()}
          </Stack>
        </Stack>
      )}
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          display: { xs: "block", md: "none" },
        }}
        elevation={3}
      >
        <LabelBottomNavigation index={activeIdx} setindex={setactiveIdx} />
      </Paper>
    </Box>
  );
};

export default Admin;
