export const urlSearchParams = (obj: any) => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      if (key === "keywords" || key === "topics" || key === "authors") {
        if (key === "keywords") {
          const array = (value as any[]).filter((x) => x !== undefined);
          return array.map((c) => `${key}[]=${c}`).join("&");
        }
        return `${key}[]=${value}`;
      }
      return `${key}=${value}`;
    })
    .join("&");
};
