import { t } from "i18next";

export const thunkResponseHandler = (
  type: string,
  value: number | string,
  Notify: any
) => {
  switch (value) {
    case 403:
      Notify(t(`${type}.forbidden`), "error");
      break;
    case 404:
      Notify(t(`${type}.notFound`), "error");
      break;
    case 400:
      Notify(t(`${type}.badRequest`), "error");
      break;
    case 409:
      Notify(t(`${type}.alreadyExist`), "error");
      break;
    default:
      Notify(t("Error.serverError"), "error");
      break;
  }
};
