import { Grid, Typography, Box } from "@mui/material";
import { t } from "i18next";

const NewStoryHelperText = () => {
  return (
    <Grid item xs={12} md={5} sx={{ mt: { xs: 2, md: 0 } }}>
      <Typography
        component={"h1"}
        fontSize={"24px"}
        fontWeight={700}
        fontFamily={"livvic"}
        sx={{ p: "0px 14px" }}
      >
        {t("newStory.tips1")}
      </Typography>
      <Typography sx={{ p: 2, pt: 1, fontSize: "16px" }} component="h6">
        {t("newStory.tips1para")}
      </Typography>
      <Box>
        <Typography
          sx={{ pl: 2, fontSize: "16px", textDecoration: "underline" }}
          component="h6"
        >
          <i>{t("newStory.tips2")}</i>
        </Typography>
        <ul style={{ paddingLeft: "54px" }}>
          <li>
            <span>
              <b>{t("newStory.tips2List.1.title")}</b>
            </span>
            &nbsp;
            <span>{t("newStory.tips2List.1.description")}</span>
          </li>
          <li>
            <span>
              <b>{t("newStory.tips2List.2.title")}</b>
            </span>
            &nbsp;
            <span>{t("newStory.tips2List.2.description")}</span>
          </li>
          <li>
            <span>
              <b>{t("newStory.tips2List.3.title")}</b>
            </span>
            &nbsp;
            <span>{t("newStory.tips2List.3.description")}</span>
          </li>
          <li>
            <span>
              <b>{t("newStory.tips2List.4.title")}</b>
            </span>
            &nbsp;
            <span>{t("newStory.tips2List.4.description")}</span>
          </li>
          <li>
            <span>
              <b>{t("newStory.tips2List.5.title")}</b>
            </span>
            &nbsp;
            <span>{t("newStory.tips2List.5.description")}</span>
          </li>
        </ul>
        <Box
          sx={{
            bgcolor: "#fcf7ee",
            ml: 2,
            mt: 2,
            p: "12px 20px",
            maxWidth: "770px",
          }}
        >
          <Typography
            component={"h1"}
            fontSize={"18px"}
            fontWeight={700}
            fontFamily={"livvic"}
            sx={{ color: "#671A19" }}
          >
            {t("newStory.tips3.title")}
          </Typography>
          <Typography fontFamily={"livvic"} sx={{ mt: "4px" }}>
            {t("newStory.tips3.description")}
          </Typography>
        </Box>
        <Box
          sx={{
            bgcolor: "#fcf7ee",
            p: "12px 20px",
            mt: "12px",
            ml: 2,
          }}
        >
          <Typography
            component={"h1"}
            fontSize={"18px"}
            fontWeight={700}
            fontFamily={"livvic"}
            sx={{ color: "#671A19" }}
          >
            {t("newStory.tips4.title")}
          </Typography>
          <Typography fontFamily={"livvic"} sx={{ mt: "4px" }}>
            {t("newStory.tips4.description")}
          </Typography>
          <Typography fontFamily={"livvic"} sx={{ mt: "4px" }}>
            {t("newStory.tips4.description2")}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default NewStoryHelperText;
