import country from "../static/countires.json";
export const countryDecryoter = (code: string) => {
  if (code === "") {
    return "-";
  } else {
    const coun = country.find((c) => c.alpha2 === code.toLowerCase());
    if (coun) {
      return coun?.en;
    } else {
      return "-";
    }
  }
};
