import {
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  Autocomplete,
  Chip,
} from "@mui/material";

interface props {
  label: string;
  options: any[];
  error: boolean | undefined;
  onChange: any;
  onBlur: any;
  valueLabel?: string;
  helperText: string | false | never[] | string[] | undefined;
  multiple: boolean;
  searchType?: boolean;
  placeholder?: string;
  value?: any;
}
const FormSelectBox = (props: props) => {
  return (
    <FormControl fullWidth error={props.error}>
      <FormLabel
        error={false}
        sx={{ fontFamily: "livvic", fontWeight: 400, color: "black" }}
      >
        {props.label}
      </FormLabel>
      <Autocomplete
        multiple={props.multiple}
        defaultValue={props.value}
        limitTags={2}
        onBlur={props.onBlur}
        isOptionEqualToValue={(option, value) => {
          return typeof option === "string"
            ? option === value
            : option?._id === value?._id;
        }}
        freeSolo
        onChange={(_, val) => {
          if (props.valueLabel === "en") {
            props.onChange(val?.alpha2);
          } else if (props.valueLabel === "native_name") {
            props.onChange(val?.code);
          } else {
            props.onChange(val);
          }
        }}
        sx={{
          border: "none",
          bgcolor: "#fff5e6",
          "& fieldset": { border: "none" },
        }}
        size="small"
        filterSelectedOptions
        options={props.options}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : props.valueLabel
            ? props.valueLabel === "authors"
              ? `${option?.firstName} ${option?.lastName}`
              : option[props.valueLabel]
            : option
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              sx={{ bgcolor: "#f5f5f5" }}
              label={
                typeof option === "string"
                  ? option
                  : props.valueLabel
                  ? props.valueLabel === "authors"
                    ? `${option?.firstName} ${option?.lastName}`
                    : option[props.valueLabel]
                  : option
              }
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            placeholder={props.placeholder}
            {...params}
            variant="outlined"
            error={props.error}
          />
        )}
      />
      <FormHelperText sx={{ m: 0 }}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default FormSelectBox;
