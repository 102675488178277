import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getKeywordsAction,
  getTopicsAction,
} from "../../features/filterSlice/filter.action";
import { filterSelector } from "../../features/filterSlice/filter.slice";
import { useAppSelector, useAppDispatch } from "../../hooks";
import NewStoryHelperText from "../NewStoryHelperText";
import UpdateStoryEditor from "./UpdateStoryEditor";

const UpdateStory = ({ story }: { story: storyType }) => {
  const { keywords, topics } = useAppSelector(filterSelector);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    dispatch(getKeywordsAction({language: i18n.resolvedLanguage ?? "en-US"}));
    dispatch(getTopicsAction({language: i18n.resolvedLanguage ?? "en-US"}));
  }, []);
  return (
    <Box className="newStory-container">
      <Grid container spacing={0} rowGap={0}>
        <Grid item xs={12}>
          <Typography className="newStory-title">
            {t("Edit Story")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <UpdateStoryEditor
            story={story}
            keywords={keywords}
            topics={topics}
          />
        </Grid>
        <NewStoryHelperText />
      </Grid>
    </Box>
  );
};

export default UpdateStory;
