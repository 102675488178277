import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { t } from "i18next";
import { Clear } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { debounce } from "lodash";
import { useAppSelector } from "../../hooks";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid #9E9E9E",
  marginLeft: 0,
  width: "300px",
  "@media (max-width: 600px)": {
    display: "flex",
    // width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "205px",
    "@media (max-width: 600px)": {
      width: "auto",
    },
  },
}));

const SearchBar = (props: {
  searchStr: string;
  index: number;
  setsearchStr: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [str, setStr] = React.useState("");
  const handleSearch = debounce((e) => {
    props.setsearchStr(e.target.value);
  }, 500);
  React.useEffect(() => {
    setStr("");
  }, [props.index]);
  return (
    <>
      <Box sx={{ flexGrow: 1, mr: 1 }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "#616161" }} />
          </SearchIconWrapper>
          <StyledInputBase
            value={str}
            onChange={(e) => {
              setStr(e.target.value);
              handleSearch(e);
            }}
            placeholder={t("Search")}
            inputProps={{ "aria-label": "search", style: { fontSize: 14 } }}
          />
          <IconButton
            sx={{ display: props.searchStr !== "" ? "" : "none" }}
            size="small"
            onClick={() => {
              props.setsearchStr("");
              setStr("");
            }}
          >
            <Clear fontSize="small" sx={{ color: "#616161" }} />
          </IconButton>
        </Search>
      </Box>
    </>
  );
};
export default SearchBar;
