import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/SendOutlined";
import FileCopyIcon from "@mui/icons-material/PersonRemoveOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import Eye from "@mui/icons-material/VisibilityOutlined";
import { IconButton } from "@mui/material";
import { t } from "i18next";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function UserAction({
  user,
  anchorEl,
  sendActivationLink,
  open,
  handleClose,
  disableUser,
}: {
  user: User;
  anchorEl: any;
  open: boolean;
  handleClose: any;
  sendActivationLink: (userId: string | null | undefined) => void;
  disableUser: (userId: string | null | undefined, status: number) => void;
}) {
  return (
    <div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {user.status !== 2 && (
          <MenuItem
            onClick={() => {
              disableUser(user?._id, user.status === 1 ? 3 : 1);
              handleClose();
            }}
            disableRipple
          >
            <FileCopyIcon />
            {user.status === 1 ? t("userMenu.disable") : t("userMenu.enable")}
          </MenuItem>
        )}
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            sendActivationLink(user?._id);
            handleClose();
          }}
          disableRipple
        >
          <ArchiveIcon />
          {t("userMenu.resend")}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
