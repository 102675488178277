import { RootState } from "../../store";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { validateSessionAction } from "./auth.action";
type InitialType = {
  verified: boolean;
  loading: boolean;
};
const initialState: InitialType = {
  verified: false,
  loading: false,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginstate: (state, action) => {
      state.verified = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(validateSessionAction.fulfilled, (state, action) => {
        state.verified = true;
        state.loading = false;
        return state;
      })
      .addCase(validateSessionAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(validateSessionAction.rejected, (state) => {
        state.verified = false;
        state.loading = false;
        return state;
      });
  },
});
export const { setLoginstate } = authSlice.actions;
export const authSelector = (state: RootState) => state.auth;
export default authSlice.reducer;
