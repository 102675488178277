import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { dateFormatter } from "../../utils/dateFormatter";
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  CardActions,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import languages from "../../static/languages.json";
import { useState } from "react";
import EditIcon from "@mui/icons-material/EditOutlined";
import { t } from "i18next";
import { StyledMenu } from "../UserList/UserAction";
import { useAppDispatch } from "../../hooks";
import { changeStoryTranslation } from "../../features/storySlice/story.action";
import { useNotification } from "../../hooks/useNotification";
import ApprovalModal from "../ApprovalModal";
import ProfileModal from "../UserList/ProfileModal";
import { setCurrentStory } from "../../features/storySlice/story.slice";
import DeleteModal from "../DeleteModal";
import { useNavigate } from "react-router-dom";

interface StyleBadegeProps extends BadgeProps {
  bgcolor: string;
  right: number;
}
const StyledBadge = styled(Badge)<StyleBadegeProps>(
  ({ theme, bgcolor, right }) => ({
    "& .MuiBadge-badge": {
      right: right,
      borderRadius: "20px",
      backgroundColor: bgcolor,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "14px 10px",
    },
  })
);

export default function StoryCard({
  stories,
  admin,
  mystories,
}: {
  stories: storyType[] | [];
  admin: boolean;
  mystories?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentSelectedStory, setselectedStory] = useState<storyType | null>(
    null
  );
  const [user, setuser] = useState<User | null>(null);
  const [loader, setLoader] = useState<string | null>(null);
  const [dialogstory, setStory] = useState<storyType | null>(null);
  const [deleteStory, setdeleteStory] = useState<storyType | null>(null);
  const notify = useNotification();
  const navigate = useNavigate()

  const dispatch = useAppDispatch();
  const handleOpen = (e: any, story: storyType) => {
    setAnchorEl(e.currentTarget);
    setselectedStory(story);
  };
  const changeLanguage = async (language: string, id: string | undefined) => {
    if (id) {
      setLoader(id);
      const res = await dispatch(changeStoryTranslation({ id, language }));
      if (res.meta.requestStatus === "rejected") {
        if(res?.payload === 401){
          return navigate("/login")
        }
        return notify("Error while translating this story", "error");
      }
      setLoader(null);
    }
  };
  return (
    <>
      {stories.length ? (
        stories.map((story, idx) => {
          if (mystories) {
            return (
              <StyledBadge
                sx={{ width: "100%", mt: 3 }}
                key={idx}
                badgeContent={
                  <Typography sx={{ fontFamily: "livvic", fontsize: 14 }}>
                    {story?.published ? t("Approved") : t("Pending approval")}
                  </Typography>
                }
                color="success"
                right={story?.published ? 55 : 80}
                bgcolor={story?.published ? "43A047" : "grey"}
              >
                <Card sx={{ width: "100%" }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        onClick={() =>
                          typeof story.createdBy !== "string" &&
                          story.createdBy !== undefined &&
                          setuser(story.createdBy)
                        }
                        sx={{ width: 56, height: 56, cursor: "pointer" }}
                        src={
                          typeof story.createdBy !== "string"
                            ? story.createdBy?.profilePic
                            : ""
                        }
                      />
                    }
                    action={
                      <>
                        {!story?.published && (
                          <IconButton
                            onClick={(e) => dispatch(setCurrentStory(story))}
                            disabled={
                              story?.published ? story?.published : false
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={(e) => handleOpen(e, story)}>
                          <MoreHorizIcon />
                        </IconButton>
                      </>
                    }
                    titleTypographyProps={{
                      sx: {
                        fontFamily: "livvic",
                        fontSize: 18,
                        cursor: "pointer",
                      },
                      onClick: () =>
                        typeof story.createdBy !== "string" &&
                        story.createdBy !== undefined &&
                        setuser(story.createdBy),
                    }}
                    subheaderTypographyProps={{ sx: { fontFamily: "livvic" } }}
                    title={
                      typeof story.createdBy !== "string" &&
                      `${story.createdBy?.firstName} ${story.createdBy?.lastName}`
                    }
                    subheader={dateFormatter(
                      story.language,
                      new Date(story?.createdAt as string)
                    )}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Typography
                      sx={{
                        mb: 1,
                        fontFamily: "livvic",
                        fontSize: 18,
                        fontWeight: 600,
                      }}
                      variant="body2"
                      color="text.secondary"
                    >
                      {story.title}
                    </Typography>
                    {story.keywords &&
                      story.keywords?.map((ele: any, i) => {
                        return (
                          <Typography
                            variant="body2"
                            key={i}
                            sx={{
                              display: "inline",
                              bgcolor: "#f9bb30e6",
                              p: "6px 12px",
                              mr: 1,
                              borderRadius: "20px",
                            }}
                            color="text.secondary"
                          >
                            #{ele?.name ?? ele}
                          </Typography>
                        );
                      })}
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: story.story,
                      }}
                      sx={{ mt: 1 }}
                      variant="body2"
                      color="text.secondary"
                    />
                    <Typography variant="body2" color="text.secondary">
                      <b>{(story.topic as any)?.name ?? story.topic}</b>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <b>{t("story.lesson")}</b>: {story.lesson}
                    </Typography>
                  </CardContent>
                  {admin && (
                    <CardActions disableSpacing>
                      <Button
                        sx={{ ml: "auto" }}
                        onClick={() => setStory(story)}
                      >
                        {t("Publish")}
                      </Button>
                    </CardActions>
                  )}
                </Card>
              </StyledBadge>
            );
          }
          return (
            <Card key={idx} sx={{ maxWidth: "770px" }}>
              <CardHeader
                avatar={
                  <Avatar
                    onClick={() =>
                      typeof story.createdBy !== "string" &&
                      story.createdBy !== undefined &&
                      setuser(story.createdBy)
                    }
                    sx={{ width: 56, height: 56, cursor: "pointer" }}
                    src={
                      typeof story.createdBy !== "string"
                        ? story.createdBy?.profilePic
                        : ""
                    }
                  />
                }
                titleTypographyProps={{
                  sx: {
                    fontFamily: "livvic",
                    fontSize: 18,
                    cursor: "pointer",
                  },
                  onClick: () =>
                    typeof story.createdBy !== "string" &&
                    story.createdBy !== undefined &&
                    setuser(story.createdBy),
                }}
                subheaderTypographyProps={{ sx: { fontFamily: "livvic" } }}
                title={
                  typeof story.createdBy !== "string" &&
                  `${story.createdBy?.firstName} ${story.createdBy?.lastName}`
                }
                action={
                  <>
                    {admin && (
                      <IconButton
                        disabled={story?.published ? story?.published : false}
                        onClick={(e) => dispatch(setCurrentStory(story))}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={(e) => handleOpen(e, story)}>
                      <MoreHorizIcon />
                    </IconButton>
                  </>
                }
                sx={{ fontFamily: "livvic", fontSize: 14 }}
                subheader={dateFormatter(
                  story.language,
                  new Date(story?.createdAt as string)
                )}
              />
              <CardContent sx={{ pt: 0 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontFamily: "livvic",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                  variant="body2"
                  color="text.secondary"
                >
                  {story.title}
                </Typography>
                <Stack
                  spacing={0}
                  rowGap={1}
                  columnGap={1}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                >
                  {story.keywords &&
                    story.keywords?.map((ele: any, i) => {
                      return (
                        <Typography
                          variant="body2"
                          key={i}
                          sx={{
                            display: "inline",
                            bgcolor: "#f9bb30e6",
                            p: "6px 12px",
                            width: "auto",
                            borderRadius: "20px",
                            fontFamily: "livvic",
                          }}
                          color="text.secondary"
                        >
                          #{ele?.name ?? ele}
                        </Typography>
                      );
                    })}
                </Stack>
                <Typography
                  sx={{ fontFamily: "livvic", mt: 1 }}
                  variant="body2"
                  color="text.secondary"
                >
                  <b>{(story.topic as any)?.name ?? story.topic}</b>
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: story.story,
                  }}
                  sx={{ mt: 1, fontFamily: "livvic" }}
                  variant="body2"
                  color="text.secondary"
                />
                <Typography
                  sx={{ fontFamily: "livvic", mt: 1 }}
                  variant="body2"
                  color="text.secondary"
                >
                  <b>{t("story.lesson")}</b>: {story.lesson}
                </Typography>
              </CardContent>
              {admin && (
                <CardActions disableSpacing>
                  <Button
                    sx={{
                      ml: "auto",
                      fontFamily: "livvic",
                      color: "#781914",
                      fontWeight: 600,
                    }}
                    onClick={() => setdeleteStory(story)}
                  >
                    {t("Delete")}
                  </Button>
                  <Button
                    sx={{ fontFamily: "livvic", fontWeight: 600 }}
                    onClick={() => setStory(story)}
                  >
                    {story?.published ? t("Unpublish") : t("Publish")}
                  </Button>
                </CardActions>
              )}
            </Card>
          );
        })
      ) : (
        <Box
          sx={{
            width: { xs: "100%", md: "770px" },
            bgcolor: "#fcf7ee",
            display: "flex",
            p: 10,
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontFamily: "livvic", fontWeight: 500 }}
          >
            {t("admin.noStories")}
          </Typography>
        </Box>
      )}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        style={{
          maxHeight: "300px",
        }}
        anchorEl={anchorEl}
        open={Boolean(currentSelectedStory)}
        onClose={() => {
          setAnchorEl(null);
          setselectedStory(null);
        }}
      >
        {languages.map((language, index) => {
          return (
            <MenuItem
              key={index}
              disabled={language.code === currentSelectedStory?.language}
              onClick={() => {
                setAnchorEl(null);
                setselectedStory(null);
                setTimeout(
                  () =>
                    changeLanguage(language.code, currentSelectedStory?._id),
                  0
                );
              }}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${language.flag?.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${language.flag?.toLowerCase()}.png`}
                alt=""
              />
              <Typography sx={{ pl: 2 }}>{language.native_name}</Typography>
            </MenuItem>
          );
        })}
      </StyledMenu>
      <ProfileModal selectedUser={user} setselectedUser={setuser} />
      {admin && <ApprovalModal story={dialogstory} setStory={setStory} />}
      {admin && <DeleteModal story={deleteStory} setStory={setdeleteStory} />}
    </>
  );
}
