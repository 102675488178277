import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers, getStories } from "./admin.type";
import { AxiosError } from "axios";
import { t } from "i18next";
import {
  getUsersService,
  getStoriesService,
} from "../../services/adminservice";
import { urlSearchParams } from "../../utils/urlParamsCreator";

export const getStoriesAction = createAsyncThunk(
  getStories,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getStoriesService();
      return response.data;
    } catch (error: any) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const getUsersAction = createAsyncThunk(
  getUsers,
  async (filters: any, { rejectWithValue }) => {
    try {
      const params = filters ? `?${urlSearchParams(filters)}` : "";
      const response = await getUsersService(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);
