import { axiosApi } from "../config/axiosApi";

export const getKeywordsService = (params: string) =>
  axiosApi.get(`/keywords${params}`);
export const getTopicsService = (params: string) =>
  axiosApi.get(`/topics${params}`);
export const getAuthorService = (language: string) =>
  axiosApi.get(`/users/creators?language=${language}`);
export const publishTags = (id: string, bool: boolean) =>
  axiosApi.patch(`/keywords/${id}/publish`, { publish: bool });
export const publisheTopic = (id: string, bool: boolean) =>
  axiosApi.patch(`/topics/${id}/publish`, { publish: bool });

export const deleteTagService = (id: string) =>
  axiosApi.delete(`/keywords/${id}`);

export const deleteTopicService = (id: string) =>
  axiosApi.delete(`/topics/${id}`);
