import { Link, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { useFormik } from "formik";
import { useState } from "react";
import { useNotification } from "../../hooks/useNotification";
import { thunkResponseHandler } from "../../utils/thunkResponseHandler";
import { ResetPasswordSchema } from "../../utils/validation";
import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import FormInputs from "../../components/FormInputs";
import { resetpasswordService } from "../../services/authservice";

const ResetPassword = () => {
  const { token } = useParams();
  const Notify = useNotification();
  const [disabled, setdisabled] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      if (disabled) return;
      setdisabled(true);
      const obj = {
        ...values,
        confirmPassword: undefined,
      };
      await handleSubmit(obj);
    },
  });
  const handleSubmit = async (values: resetpassword) => {
    try {
      const res = await resetpasswordService(values, token as string);
      if (res.status === 200) {
        setdisabled(false);
        Notify("Successfully changed your password", "success");
        navigate("/login")
      }
      setdisabled(false);
    } catch (error) {
      setdisabled(false);
      navigate("/login")
      return thunkResponseHandler("response", 0, Notify);
    }
  };
  return (
    <Box className="reset-form-container" sx={{ p: { xs: 4, md: 0 } }}>
      <Typography className="reset-title">{t("Reset.title")}</Typography>
      <form onSubmit={formik.handleSubmit} className="reset-form">
        <FormInputs
          name="password"
          label={t("reset.passwordLabel")}
          type={"password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.touched.password && formik.errors.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
        />
        <FormInputs
          name="confirmPassword"
          label={t("reset.confirmPasswordLabel")}
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
        />
        <Box className="reset-button-container">
          <Button
            variant="contained"
            type="submit"
            disableElevation
            className="reset-button"
          >
            {t("reset.button")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ResetPassword;
