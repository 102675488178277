import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import InfoIcon from "@mui/icons-material/PeopleOutline";
import SupportAgentIcon from "@mui/icons-material/AutoStoriesOutlined";
import TagIcon from "@mui/icons-material/TagOutlined";
import TopicIcon from "@mui/icons-material/TopicOutlined";
import { t } from "i18next";

interface props {
  index: number;
  setindex: any;
}
export default function LabelBottomNavigation(props: props) {
  return (
    <BottomNavigation
      value={props.index}
      onChange={(e, v) => props.setindex(v)}
    >
      <BottomNavigationAction
        label={t("admin.userlist")}
        value={0}
        icon={<InfoIcon />}
      />
      <BottomNavigationAction
        label={t("admin.storylist")}
        value={1}
        icon={<SupportAgentIcon />}
      />
      <BottomNavigationAction
        value={2}
        label={t("admin.tags")}
        icon={<TagIcon />}
      />
      <BottomNavigationAction
        label={t("admin.topics")}
        value={3}
        icon={<TopicIcon />}
      />
    </BottomNavigation>
  );
}
