import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { t } from "i18next";
import { useNotification } from "../../hooks/useNotification";
import { useAppDispatch } from "../../hooks";
import { deleteStoryAction } from "../../features/storySlice/story.action";

interface props {
  story: storyType | null;
  setStory: any;
}
export default function DeleteModal(props: props) {
  const Notify = useNotification();
  const dispatch = useAppDispatch();
  const handlePublish = async () => {
    if (props.story && props.story._id) {
      const res = await dispatch(deleteStoryAction(props.story._id));
      if (res.meta.requestStatus === "rejected" && res.meta.rejectedWithValue) {
        props.setStory(null);
        return Notify(t("Error.serverError"), "error");
      }
      Notify(t("Story deleted successfully"), "success");
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={Boolean(props.story)}
        onClose={() => props.setStory(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontFamily: "livvic" }}>
          {t(`Delete this story?`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontFamily: "livvic" }}
          >
            Do you really want to delete this story
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setStory(null)}
            sx={{ fontFamily: "livvic" }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handlePublish}
            autoFocus
            sx={{ fontFamily: "livvic" }}
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
