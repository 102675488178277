import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginUser, signupuser, updatedUser, logoutUser } from "./user.type";
import {
  loginUserService,
  signupUserService,
  updateUserService,
  logoutUserService
} from "../../services/authservice";
import { AxiosError } from "axios";
import { t } from "i18next";
import { setLoginstate } from "../authSlice/auth.slice";

export const loginUserAction = createAsyncThunk(
  loginUser,
  async (user_details: loginUserType, { rejectWithValue, dispatch }) => {
    try {
      const response = await loginUserService(user_details);
      if (response.status === 200) {
        dispatch(setLoginstate(true));
        return response.data;
      }
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const signupUserAction = createAsyncThunk(
  signupuser,
  async (user_details: signupUserType, { rejectWithValue }) => {
    try {
      const response = await signupUserService(user_details);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const editUserProfile = createAsyncThunk(
  updatedUser,
  async (
    { user_details, id }: { user_details: profileUserType; id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateUserService(user_details, id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);

export const logoutUserAction = createAsyncThunk(logoutUser, async () => {
  try {
    const response = await logoutUserService();
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
});
