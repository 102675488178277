import {
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  Autocomplete,
  Chip,
  createFilterOptions,
} from "@mui/material";

interface props {
  label: string;
  options: any[];
  error: boolean | undefined;
  onChange: any;
  onBlur: any;
  valueLabel?: string;
  helperText: string | false | never[] | string[] | undefined;
  multiple: boolean;
  searchType?: boolean;
  placeholder?: string;
  value?: any;
}
const TopicSelect = (props: props) => {
  const filter = createFilterOptions<any>();
  return (
    <FormControl fullWidth error={props.error}>
      <FormLabel
        error={false}
        sx={{ fontFamily: "livvic", fontWeight: 400, color: "#781914" }}
      >
        {props.label}
      </FormLabel>
      <Autocomplete
        multiple={props.multiple}
        value={props.value}
        sx={{
          border: "none",
          bgcolor: "#fff5e6",
          "& fieldset": { border: "none" },
        }}
        onChange={(_, val) => {
          props.onChange(val)
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.name
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push(inputValue);
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={props.options}
        size="small"
        renderOption={(props, option) => <li {...props}>{typeof option ==="string" ? option :option.name}</li>}
        freeSolo
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : props.valueLabel
            ? props.valueLabel === "authors"
              ? `${option?.firstName} ${option?.lastName}`
              : option[props.valueLabel]
            : option
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              sx={{ bgcolor: "#f5f5f5" }}
              label={
                typeof option === "string"
                  ? option
                  : props.valueLabel
                  ? props.valueLabel === "authors"
                    ? `${option?.firstName} ${option?.lastName}`
                    : option[props.valueLabel]
                  : option
              }
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            placeholder={props.placeholder}
            {...params}
            variant="outlined"
            error={props.error}
          />
        )}
      />
      <FormHelperText sx={{ m: 0 }}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
export default TopicSelect;
