import { t } from "i18next";
import * as yup from "yup";

// Assuming t() function is defined somewhere to handle translations

export const LoginSchema = yup.object({
  email: yup
    .string()
    .email(t("Enter a valid email"))
    .required(t("Email is required")),
  password: yup.string().trim().required(t("Password is required")),
});

export const SignUpSchema = yup.object({
  firstName: yup.string().trim().required(t("First name is required")),
  lastName: yup.string().trim().required(t("Last name is required")),
  email: yup
    .string()
    .email(t("Enter a valid email"))
    .required(t("Email is required")),
  password: yup.string().trim().required(t("Password is required")),
  confirmPassword: yup
    .string()
    .required(t("Please confirm your password"))
    .oneOf([yup.ref("password")], t("Passwords must match")),
  dob: yup.date().required(t("Dob is required")),
  country: yup.string(),
  gender: yup.string().required(t("Gender is required")),
});

export const StorySchema = yup.object({
  title: yup.string().trim().required(t("Title is required")),
  rawText: yup
    .string()
    .trim()
    .min(2, t("Story is required"))
    .required(t("Story is required")),
  story: yup.string().trim().required(t("Story is required")),
  keywords: yup.array().min(1, t("Tags are required")),
  topic: yup
    .mixed()
    .test(
      "is-object-or-string",
      "Field must be an object or a string",
      (value) => {
        return typeof value === "object" || typeof value === "string";
      }
    )
    .required(t("Topic is required")),
  country: yup.string(),
  language: yup.string().required(t("Language is required")),
  lesson: yup.string().trim().required(t("Lesson is required")),
});

export const ResetPasswordSchema = yup.object({
  password: yup.string().trim().required(t("Password is required")),
  confirmPassword: yup
    .string()
    .required(t("Please confirm your password"))
    .oneOf([yup.ref("password")], t("Passwords must match")),
});

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email(t("Enter a valid email"))
    .required(t("Email is required")),
});

export const ProfileSchema = yup.object({
  firstName: yup.string().trim().required(t("First name is required")),
  lastName: yup.string().trim().required(t("Last name is required")),
  email: yup
    .string()
    .email(t("Enter a valid email"))
    .required(t("Email is required")),
});

export const StorySearchSchema = yup.object({});
