import { Stack, Button, CircularProgress, Chip } from "@mui/material";
import ShowTable from ".";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { filterSelector } from "../../features/filterSlice/filter.slice";
import { useEffect, useState } from "react";
import SearchBar from "../SearchBar";
import "./wrapper.style.css";
import {
  getKeywordsAction,
  getTopicsAction,
} from "../../features/filterSlice/filter.action";
import { useTranslation } from "react-i18next";

const TableWrapper = (props: any) => {
  const { loading, keywords, topics } = useAppSelector(filterSelector);
  const [searchStr, setsearchStr] = useState("");
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [toggle, settoggle] = useState(false);
  const [status, setstatus] = useState<null | number>(null);

  useEffect(() => {
    dispatch(
      getKeywordsAction({
        language: i18n.resolvedLanguage ?? "en-US",
        name: searchStr.trim() !== "" ? searchStr : undefined,
        published: status ? (status === 1 ? true : false) : undefined,
      })
    );
    dispatch(
      getTopicsAction({
        language: i18n.resolvedLanguage ?? "en-US",
        name: searchStr.trim() !== "" ? searchStr : undefined,
        published: status ? (status === 1 ? true : false) : undefined,
      })
    );
  }, [toggle, props.index, status, searchStr]);

  useEffect(() => {
    setstatus(null);
    setsearchStr("");
  }, [props.index]);

  return (
    <Stack className="communication-container" spacing={2}>
      <Stack
        className="communication-header"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={2}
      >
        <SearchBar
          index={props.index}
          searchStr={searchStr}
          setsearchStr={setsearchStr}
        />
        <Stack
          flexDirection="row"
          alignItems="center"
          flex={1}
          justifyContent="flex-end"
        >
          <Chip
            label={"Published"}
            clickable
            variant={status !== 1 ? "outlined" : "filled"}
            sx={{
              borderRadius: 0,
              borderRight: 0,
              fontFamily: "livvic",
              bgcolor: status !== 1 ? "#fff5e6" : "#f9bb30",
              "&:hover": {
                backgroundColor: "#f9bb30",
              },
            }}
            color={status !== 1 ? "default" : "primary"}
            onClick={() => setstatus(1)}
          />
          <Chip
            label={"Unpublished"}
            clickable
            variant={status !== 2 ? "outlined" : "filled"}
            sx={{
              borderRadius: 0,
              borderRight: 0,
              fontFamily: "livvic",
              bgcolor: status !== 2 ? "#fff5e6" : "#f9bb30",
              "&:hover": {
                backgroundColor: "#f9bb30",
              },
            }}
            color={status !== 2 ? "default" : "primary"}
            onClick={() => setstatus(2)}
          />
          <Chip
            label={"Both"}
            clickable
            variant={status !== null ? "outlined" : "filled"}
            sx={{
              borderRadius: 0,
              fontFamily: "livvic",
              bgcolor: status !== null ? "#fff5e6" : "#f9bb30",
              "&:hover": {
                backgroundColor: "#f9bb30",
              },
            }}
            color={status !== null ? "default" : "primary"}
            onClick={() => setstatus(null)}
          />
        </Stack>
      </Stack>
      {loading ? (
        <Stack
          sx={{ height: "30vh" }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <ShowTable
          array={props.type === "tags" ? keywords : topics}
          type={props.type}
          settoggle={settoggle}
        />
      )}
    </Stack>
  );
};

export default TableWrapper;
