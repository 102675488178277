import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import FormInputs from "../../components/FormInputs";
import "./style.css";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../utils/validation";
import { thunkResponseHandler } from "../../utils/thunkResponseHandler";
import { useNotification } from "../../hooks/useNotification";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { forgotPasswordService } from "../../services/authservice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const Notify = useNotification();
  const [disabled, setdisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      if (disabled) return;
      setdisabled(true);
      await handleSubmit(values);
    },
  });
  const handleSubmit = async (values: { email: string }) => {
    try {
      const res = await forgotPasswordService(values);
      if (res.status === 200) {
        setdisabled(false);
        Notify("Successfully sent password reset email", "success");
      }
      setdisabled(false);
      return navigate("/");
    } catch (error) {
      setdisabled(false);
      return thunkResponseHandler("response", 0, Notify);
    }
  };
  return (
    <Box sx={{ p: { xs: 4, md: 0 } }}>
      <Helmet>
        <title>{t("Home.title")}</title>
      </Helmet>
      <Box className="forgotPassword-container">
        <Box className="forgotPassword-form-container">
          <Typography className="forgot-title">{t("forgot.title")}</Typography>
          <form onSubmit={formik.handleSubmit} className="forgotPassword-form">
            <FormInputs
              name="email"
              label={t("forgotPassword.emailLabel")}
              type={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />

            <Box>
              <Link className="forgotPassword-forgot-text" to={"/login"}>
                {t("forgotPassword.goback")}
              </Link>
            </Box>
            <Box className="forgotPassword-button-container">
              <Button
                variant="contained"
                type="submit"
                disableElevation
                className="forgotPassword-button"
              >
                {t("forgotPassword.submit")}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
