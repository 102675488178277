import { Box } from "@mui/material";
import logo from "../../assets/icon/logo.svg";
import "./style.css";

const Loading = () => {
  return (
    <Box className="loading-container">
      <svg viewBox="0 0 1500 1500" style={{ padding: 20 }}>
        <use href={`${logo}#holo`} />
      </svg>
    </Box>
  );
};

export default Loading;
