import { RootState } from "../../store";
import { createSlice } from "@reduxjs/toolkit";
import {
  editUserProfile,
  loginUserAction,
  logoutUserAction,
  signupUserAction,
} from "./user.action";
type InitialStateType = {
  userData: User | null;
  loading: boolean;
  error: string | null;
};
const initialState: InitialStateType = {
  userData: null,
  loading: false,
  error: null,
};
export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLogoutState: (state) => {
      state.userData = null;
    },
    fillUserDetails: (state, action) => {
      state.userData = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUserAction.fulfilled, (state, action) => {
        state.userData = action?.payload?.user;
        state.loading = false;
        return state;
      })
      .addCase(loginUserAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(loginUserAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(signupUserAction.fulfilled, (state, action) => {
        state.loading = false;
        return state;
      })
      .addCase(signupUserAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(signupUserAction.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(editUserProfile.fulfilled, (state, action) => {
        state.userData = action?.payload;
        state.loading = false;
        return state;
      })
      .addCase(editUserProfile.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(editUserProfile.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(logoutUserAction.fulfilled, (state, action) => {
        state.userData = null;
        state.loading = false;
        return state;
      })
      .addCase(logoutUserAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(logoutUserAction.rejected, (state) => {
        state.loading = false;
        return state;
      });
  },
});
export const { setLogoutState, fillUserDetails } = userSlice.actions;
export const userSelector = (state: RootState) => state.user;
export default userSlice.reducer;
