import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { t } from "i18next";
import { validateSessionService } from "../../services/authservice";
import { validateSession } from "./auth.type";
import { fillUserDetails } from "../userSlice/user.slice";

export const validateSessionAction = createAsyncThunk(
  validateSession,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await validateSessionService();
      if (response.status === 200) {
        dispatch(fillUserDetails(response.data[0]));
        return response.data[0];
      }
      dispatch(fillUserDetails(null));
    } catch (error: any) {
      const err = error as AxiosError;
      dispatch(fillUserDetails(null));
      return rejectWithValue(
        err.response?.status || err?.status || t("Something went wrong")
      );
    }
  }
);
