import { combineReducers, configureStore } from "@reduxjs/toolkit";

import usersReducer from "../features/userSlice/user.slice";
import storyReducer from "../features/storySlice/story.slice";
import adminReducer from "../features/adminSlice/admin.slice";
import filterReducer from "../features/filterSlice/filter.slice";
import authReducer from "../features/authSlice/auth.slice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["story", "admin", "filter", "auth"],
};

const rootReducer = combineReducers({
  user: usersReducer,
  story: storyReducer,
  admin: adminReducer,
  auth: authReducer,
  filter: filterReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
